<template>
  <!--srcviewscompanydetailsbusinessvuehtmlStart-->

  <div id="defaultId1">
    <div id="cc18ed" class="udb507 u22483 pb20">
      <div id="g2ede8" class="operations-content mb10">
        <div id="gf7b16" class="operations-head">
          <span class="text_head detail_title_20">Operations</span>
        </div>
        <div class="operations-box">
          <div class="operations-box-left">
            <el-descriptions :colon="false" :column="1">
              <el-descriptions-item>
                <template slot="label">
                  <div class="operations-label-title">
                    <div class="label_text">
                      Line of Business
                    </div>
                  </div>
                  <!-- v-if="userType && hasPower == 1" -->
                  <div :class="[editcontent == 'Line of Business' ? 'edit_icon_dis' : 'edit_icon']">
                    <svg t="1718263375162" @click="edit('Line of Business')" class="icon" viewBox="0 0 1024 1024"
                      version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="11104" width="2.85rem" height="2.85rem">
                      <path
                        d="M769.824 291.542L666.519 188.237c-15.802-15.802-41.421-15.799-57.224 0l-361.94 361.94c-12.357 12.358-11.885 12.825-11.889 39.389-0.005 132.545 0.064 132.965 0.064 132.965s0.42 0.07 132.969 0.065c26.561 0 27.027 0.468 39.39-11.891L769.824 348.77c15.805-15.802 15.809-41.421 0-57.228zM394.826 664.313c-14.95 14.949-15.478 14.416-45.967 14.416-69.291 0-69.404-0.117-69.404-0.117s-0.121-0.114-0.121-69.409c0-30.489-0.529-31.017 14.416-45.963l240.425-240.425 101.071 101.076-240.42 240.422z m270.149-270.151L563.903 293.086l46.567-46.566c27.437-27.437 27.852-27.023 54.879 0.006l46.187 46.187c27.033 27.026 27.447 27.442 0.007 54.885l-46.568 46.564zM811.435 718.375H637.664c-11.609 0-21.02-9.411-21.02-21.021 0-11.611 9.41-21.021 21.02-21.021h173.771c11.612 0 21.021 9.41 21.021 21.021 0 11.61-9.409 21.021-21.021 21.021zM811.435 847.612H256.487c-11.612 0-21.021-9.411-21.021-21.021 0-11.609 9.408-21.021 21.021-21.021h554.947c11.612 0 21.021 9.411 21.021 21.021 0.001 11.61-9.408 21.021-21.02 21.021z"
                        fill="#1290C9" p-id="11105"></path>
                    </svg>
                  </div>
                </template>
                <div class="operations-label-text">
                  <div v-if="editcontent == 'Line of Business'" style="position: relative">
                    <el-input autocomplete="off" clearable type="textarea" autosize v-model="Busines"></el-input>
                    <div class="dingwei" tyle="text-align: center;">
                      <span class="contclass" @click="editcontent = '',Busines=''">Cancel</span>
                      <span class="jiange">|</span>
                      <span class="contclass" @click="SavedOperations('1')">Saved</span>
                    </div>
                  </div>
                  <div v-else>{{ LineofBusiness }}</div>
                </div>
              </el-descriptions-item>
            </el-descriptions>
            <el-descriptions :colon="false" :column="1">
              <el-descriptions-item v-if="BusinessTerritories != '-'">
                <template slot="label">
                  <div class="operations-label-title">
                    <div class="label_text">
                      Business Territories
                    </div>
                  </div>
                </template>
                <div class="operations-label-text">
                  {{ BusinessTerritories }}
                </div>
              </el-descriptions-item>
              <el-descriptions-item v-if="ImportExport != '-'">
                <template slot="label">
                  <div class="operations-label-title">
                    <div class="label_text">
                      Import & Export
                    </div>
                    </div>
                </template>
                <div class="operations-label-text">
                  {{ ImportExport }}
                  <span @click="gotoimport" v-if="ImportExport=='Yes'" class="ViewMore contclass">View More</span>
                </div>
              </el-descriptions-item>
            </el-descriptions>
          </div>
          <div class="operations-box-right">
            <el-descriptions :colon="false" :column="1">
              <el-descriptions-item>
                <template slot="label">
                  <div class="operations-label-title">
                    <div class="label_text">
                      Products/Services
                    </div>
                    </div>
                  <div :class="[editcontentSever == 'Products/Services' ? 'edit_icon_dis' : 'edit_icon']">
                    <!-- v-if="userType && hasPower == 1" -->
                    <svg t="1718263375162" @click="editcontentSever='Products/Services'" class="icon"
                      viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="11104" width="2.85rem"
                      height="2.85rem">
                      <path
                        d="M769.824 291.542L666.519 188.237c-15.802-15.802-41.421-15.799-57.224 0l-361.94 361.94c-12.357 12.358-11.885 12.825-11.889 39.389-0.005 132.545 0.064 132.965 0.064 132.965s0.42 0.07 132.969 0.065c26.561 0 27.027 0.468 39.39-11.891L769.824 348.77c15.805-15.802 15.809-41.421 0-57.228zM394.826 664.313c-14.95 14.949-15.478 14.416-45.967 14.416-69.291 0-69.404-0.117-69.404-0.117s-0.121-0.114-0.121-69.409c0-30.489-0.529-31.017 14.416-45.963l240.425-240.425 101.071 101.076-240.42 240.422z m270.149-270.151L563.903 293.086l46.567-46.566c27.437-27.437 27.852-27.023 54.879 0.006l46.187 46.187c27.033 27.026 27.447 27.442 0.007 54.885l-46.568 46.564zM811.435 718.375H637.664c-11.609 0-21.02-9.411-21.02-21.021 0-11.611 9.41-21.021 21.02-21.021h173.771c11.612 0 21.021 9.41 21.021 21.021 0 11.61-9.409 21.021-21.021 21.021zM811.435 847.612H256.487c-11.612 0-21.021-9.411-21.021-21.021 0-11.609 9.408-21.021 21.021-21.021h554.947c11.612 0 21.021 9.411 21.021 21.021 0.001 11.61-9.408 21.021-21.02 21.021z"
                        fill="#1296db" p-id="11105"></path>
                    </svg>
                  </div>
                </template>
                <div class="operations-label-text">
                  <div v-if="editcontentSever == 'Products/Services'" style="position: relative">
                    <el-input   autocomplete="off" clearable type="textarea" autosize v-model="ProductsServicescahnge"></el-input>
                    <div class="dingwei" tyle="text-align: center;">
                      <span class="contclass" @click="editcontentSever = '',ProductsServicescahnge=''">Cancel</span>
                      <span class="jiange">|</span>
                      <span class="contclass" @click="SavedOperations('2')">Saved</span>
                    </div>
                  </div>
                  <div v-else>{{ ProductsServices }}</div>
                </div>
              </el-descriptions-item>
              -
            </el-descriptions>
          </div>
        </div>
      </div>
      <!--  @click="powerArr[0]?.hasPrivilege ? '' : $bus.$emit('show-permission-dialog', '您没有权限访问该功能！')" :class="powerArr[0]?.hasPrivilege ? '' : 'vaguesuo'"-->
      <div class="" >
        <!-- :class="powerArr[0]?.hasPrivilege?'operations-content ':'vague'" -->
      <div id="g2ede8"  class="operations-content">
        <div id="gf7b16" class="operations-head">
          <span class="text_head detail_title_20">Products</span>
        </div>
        <el-table :data="tableData" style="width: 100%" empty-text="Please Upload Data" class="table_X table_height_30 table_font_size_14 table_contentHeight_40" >
          <el-table-column label="Product Image" width="200" prop="Product Img" align="center">
            <template slot-scope="scope">
              <el-upload v-if="scope.$index == inputIndex" class="avatar-uploader"
                action="" :show-file-list="false"
                :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload" :on-change="imgchange">
                <img v-if="imageUrl" :src="imageUrl" class="avatar" />
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
              <span v-else class="flex_center">
                <img style="width: 5rem; height: 5rem;" :src="'https://www.csibizsearch.com/csi/usercenter/download/resource?resource=' +scope.row.imgUrl" alt="">
              </span>
            </template>
          </el-table-column>
          <!-- <el-table-column label="SKU" prop="SKU" width="200" align="center">
            <template slot-scope="scope">
              <el-input   autocomplete="off" clearable v-if="scope.$index == inputIndex" v-model="scope.row.sku"></el-input>
              <span v-else>{{ scope.row.sku }}</span>
            </template>
          </el-table-column> -->
          <el-table-column label="Product Name" prop="ProductName" width="300"> 
            <template slot-scope="scope">
              <el-input   autocomplete="off" v-if="scope.$index == inputIndex" clearable v-model="scope.row.name"></el-input>
              <span v-else>{{ scope.row.name }}</span>
            </template>
          </el-table-column>
          <el-table-column show-overflow-tooltip label="Product Description" prop="ProductDescription">
            <template slot-scope="scope">
              <el-input   autocomplete="off" v-if="scope.$index == inputIndex" clearable v-model="scope.row.description"></el-input>
              <span v-else>{{ scope.row.description }}</span>
            </template>
          </el-table-column>
          <el-table-column label="Details" width="200" prop="" align="center">
            <template slot-scope="scope">
              <!--  -->
              <a v-if="powerArr[2]?.hasPrivilege" class="flex_center" :href="'mailto:service@csibizinfo.com?subject=information need to correct&body=Customer service, hello: %0D%0Aand Our company  isinterestedin ' +
                  scope.row.name +' of '+$parent.$parent.companyName+
                  'and  needs to %0D%0Aand obtain the price of the product. thank you '
                  ">
                  
                <svg t="1719539255609" class="icon" @click="handleEdit(scope.$index, scope.row)" viewBox="0 0 1024 1024"
                  version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="4711" width="2.28rem" height="2.28rem">
                  <path
                    d="M964.48 459.632l-94.784-82.416V84.608c0-12.368-8.24-20.608-20.608-20.608h-661.44c-12.368 0-20.624 8.24-20.624 20.608v298.784l-94.784 76.24c-6.176 4.128-8.24 10.304-8.24 16.48V950.08c0 12.368 8.24 20.608 20.608 20.608h865.44c12.384 0 20.624-8.24 20.624-20.608V476.128c0-6.192-2.08-12.368-6.192-16.48z m-96.848-26.784l49.456 41.216-49.44 32.96v-74.176zM208.24 105.216h618.192v430.656l-212.256 146.304H420.48l-212.24-146.304V105.216zM167.04 436.96v72.112l-47.392-32.96 47.392-39.152z m-61.808 78.304l274.064 187.52-274.08 206.048V515.28zM146.432 929.44L420.48 723.392h191.648l276.112 206.064H146.432z m783.024-20.608L655.392 702.784l274.064-187.52v393.584zM744 414.288H290.672v41.232H744v-41.216z m0-103.008H290.672v41.2H744v-41.2z m-329.696-103.04h-123.632v41.216h123.632v-41.216z"
                    fill="#1296db" p-id="4712"></path>
                </svg>
              </a>
              <svg v-else  @click="$bus.$emit('show-permission-dialog', '您没有权限访问该功能！')" t="1719539255609" class="icon" viewBox="0 0 1024 1024"
                version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="4711" width="2.28rem" height="2.28rem">
                <path
                  d="M964.48 459.632l-94.784-82.416V84.608c0-12.368-8.24-20.608-20.608-20.608h-661.44c-12.368 0-20.624 8.24-20.624 20.608v298.784l-94.784 76.24c-6.176 4.128-8.24 10.304-8.24 16.48V950.08c0 12.368 8.24 20.608 20.608 20.608h865.44c12.384 0 20.624-8.24 20.624-20.608V476.128c0-6.192-2.08-12.368-6.192-16.48z m-96.848-26.784l49.456 41.216-49.44 32.96v-74.176zM208.24 105.216h618.192v430.656l-212.256 146.304H420.48l-212.24-146.304V105.216zM167.04 436.96v72.112l-47.392-32.96 47.392-39.152z m-61.808 78.304l274.064 187.52-274.08 206.048V515.28zM146.432 929.44L420.48 723.392h191.648l276.112 206.064H146.432z m783.024-20.608L655.392 702.784l274.064-187.52v393.584zM744 414.288H290.672v41.232H744v-41.216z m0-103.008H290.672v41.2H744v-41.2z m-329.696-103.04h-123.632v41.216h123.632v-41.216z"
                  fill="#1296db" p-id="4712"></path>
              </svg>
            </template>
          </el-table-column>
        </el-table>
        <!-- v-if="userType && hasPower == 1" -->
        <div class="last-colmun">
          <span v-if="inputIndex == -1" @click="addInput(tableData.length)" class="contclass"><span class="mr10">+</span><span>Add product information</span></span>
          <div v-else style="text-align: center">
            <span @click="CancelAdd()" class="contclass">Cancel</span>
            <span class="jiange">|</span>
            <span class="contclass" @click="uptateProduct">Saved</span>
          </div>
        </div>
        <div v-if="inputIndex == -1" style="text-align: center; padding: 20px" class="show_AllHeight">
          <span class="contclass show_All" v-if="this.page.total>this.page.pageSize" @click="apptablelist">Show All</span>
        </div>
      </div>
    </div>
      <!-- <PermissionDialog></PermissionDialog> -->
      <ReLogin :reLoginPop="reLoginVis"></ReLogin>
    </div>
  </div>
  <!--srcviewscompanydetailsbusinessvuehtmlEnd-->
</template>
<script>
// interfaceCode
//

//importEnd

// interfaceCode

// interfaceCode
//importStart

import gui_link_content from "../../../components/gui_link_content/gui_link_content.vue";
import gui_scroll_x from "../../../components/gui_scroll_x/gui_scroll_x.vue";
//importEnd
import PermissionDialog from '@/components/power/PermissionDialog.vue';
import ReLogin from "../../../components/ReLogin.vue";


import {  getPartPrivilege, getCompanyOperating, addProducts,editCompanyPartialInfo } from "@/api/companyApi";
import { UploadProductImg, } from "@/api/userApi";
export default {
  name: "",
  props: {},
  mounted() {
    this.getlist();
    this.getPartPrivilege()
    if (this.userInfo.userInfo.userType == 3) {
      this.userType = true;
    }
    this.$emit("UpdatamarkTotalList", []);
  },
  components: {
    gui_link_content,
    gui_scroll_x,
    PermissionDialog,
    ReLogin,
  },
  data() {
    return {
      reLoginVis: false,
      userType: false,
      SkuCode: "",
      powerArr:[],
      IsSkuCode: false,
      LineofBusiness: "",
      BusinessTerritories: "",
      hasPower:0,
      Busines: "",
      ProductsServicescahnge: "",
      ImportExport: "",
      ProductsServices: "",
      imageUrl: "",
      tableData: [],
      page: {
        pageNo: 1,
        pageSize: 5,
        total: 0,
      },
      inputIndex: -1,
      editcontent: "",
      editcontentSever:'',
      dialogImageUrl: "",
      dialogVisible: false,
      userInfo: JSON.parse(localStorage.getItem("userInfo")),
      // insertData
      // srcviewscompanydetailsbusinessvueDataStart

      // hangyefenxiKey
      hangyefenxiKey: 0,
      imageName: "",
      // srcviewscompanydetailsbusinessvueDataEnd
    };
  },
  methods: {
    // IsSkuCodeMethod(){
    //   if(this.powerArr[1]?.hasPrivilege){
    //     this.IsSkuCode=true
    //   }else{
    //     this.$bus.$emit('show-permission-dialog', '您没有权限访问该功能！');
    //   }
    // },
    getPartPrivilege(){
      getPartPrivilege({
        moduleId: 419
      }).then(res=>{
        this.powerArr[0] = (res.data.filter(item => item.id=='421'))[0]
        this.powerArr[1] = (res.data.filter(item => item.id=='422'))[0]
        this.powerArr[2] = (res.data.filter(item => item.id=='423'))[0]
      })
    },
    SavedOperations(value){
      // FORM DATE类型
const params = new URLSearchParams();
      params.append('aaaId', this.$route.query.id3a);
      params.append('type',value);
      params.append('data', value=='1'?this.Busines:this.ProductsServicescahnge,);
      if(value=='1'){
        if(this.Busines==''){
   this.$message.error('Cannot be empty')
      }else{
  
      editCompanyPartialInfo(params).then(res=>{
        this.$message({
          message: res.msg,
          type: "success",
        });
        this.editcontent=''
        this.Busines=''
      })
      }
      }else{
        if(this.ProductsServicescahnge==''){
   this.$message.error('Cannot be empty')
      }else{

      editCompanyPartialInfo(params).then(res=>{
        this.editcontentSever=''
        this.ProductsServicescahnge=''
        this.$message({
          message: res.msg,
          type: "success",
        });
      })
      }
      }
   
   
    },
    changSeach() { },
    
    getlist() {

      
      
      const id3a = this.$route.query.id3a;
      getCompanyOperating({
        id3a: id3a,
        page: this.page.pageNo,
        pageSize: this.page.pageSize,
      }).then((res) => {
        if (res.code == 200) {
          this.ProductsServices = res.data.productsAndServices;
          this.LineofBusiness = res.data.lineOfBusiness;
          this.BusinessTerritories = res.data.businessTerritories;
          this.ImportExport = res.data.importExportFlag>0?'Yes':'No';
          this.tableData = res.data.productsVO.rows;
         this.hasPower=res.data.hasPower;
          this.page.total=res.data.productsVO.total
        } else {
          this.tableData = [];
        }
      }).catch(err => {
        this.reLoginVis = String(err).includes('403')
      });
    },
    uptateProduct() {
      let params = [
        {
          aaaId: this.$route.query.id3a,
          type: 3,
          customAccount: this.userInfo.userInfo.email,
          dataJson: {
            imgUrl:  this.imageUrl,
            sku: "3123",
            name: "apple",
            description: "苹果数据",
          },
          status: 1,
        },
      ];
      params.map((item) => {
        item.dataJson = JSON.stringify(item.dataJson);
      });

      addProducts(params).then((res) => {
        this.$message({
          message:'The product you submitted needs to be reviewed by backend personnel, and after successful review, it will appear on the page',
          type: "success",
          duration:6000
        });
      });
      this.inputIndex=-1
      this.getlist()
    },
    handleAvatarSuccess(res, file) {
   
    },
    imgchange(file) {
      // this.imageName = file.name;
      const reader = new FileReader();

      reader.onload = (event) => {
        const binaryData = event.target.result; // 获取二进制数据

        const formData = new FormData();
        formData.append("avatarfile", new Blob([binaryData]), file.name);
        UploadProductImg(formData).then(res => {
          if (res.code == 200) {
            this.imageUrl = 'https://www.csibizsearch.com/csi/usercenter/download/resource?resource=' + res.data;
          
            // 更新 localStorage
          } else {
            this.$message.error(res.msg);
          }
        });
      };

      reader.readAsArrayBuffer(file.raw); // 启动读取
    },
    beforeAvatarUpload(file) {

      const isJPG = file.type === "image/jpeg" || file.type === "image/png";
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
        this.$message.error("Uploading avatar images can only be in JPG format!");
      }
      if (!isLt2M) {
        this.$message.error("Uploading avatar images can only be in JPG format!");
      }

      return isJPG && isLt2M;
    },
    apptablelist() { 
      this.page.pageSize+5
      this.getlist()
    },
    CancelAdd() {
      this.inputIndex=-1
      this.getlist();
    },
    edit(content) {
      this.editcontent = content;

      //   this.tableData.push({ ...this.tableData[index-1] });
    },
    // insertMethod
    // srcviewscompanydetailsbusinessvueMethodStart
    // returnPage
    handleEdit(index, row) {
    },
    returnPage() {
      history.go(-1);
    }, // reShowPopver1
    reShowPopver1() {
      debugger;
      this.$refs.myPopover.doClose();

      this.$nextTick(() => {
        this.$refs.myPopover.doShow();
      });
    },
    addInput(index) {
      this.inputIndex = index;
      this.tableData.push({ ...this.tableData[index - 1] });
    },
    gotoimport() {
      if(this.powerArr[0]?.hasPrivilege){
        this.$router.push({
        path: "import&Export",
        params: {
          id: this.$route.params.id,
        },
        query: {
          id3a: this.$route.query.id3a,
          companyCountry: this.$route.query.companyCountry,
          SACode1: this.$route.query.SACode1,
          companyCountryEn: this.$route.query.companyCountryEn,
          idpower:430
        },
      });
      // window.location.reload();
      }else{
        this.$bus.$emit('show-permission-dialog', '您没有权限访问该功能！');
      }
    
    },
    // srcviewscompanydetailsbusinessvueMethodEnd
  },
  computed: {},
};
</script>
<style scoped>
.ua915f {
  margin-right: 0em;
  padding-left: 0.5em;
  padding-right: 0.5em;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  margin-top: 0em;
  margin-left: 0em;
  margin-bottom: 0em;
}

.operations-head {
  height: 3.5714285714285716em;
  line-height: 3.5714285714285716em;
  
  /* font-family: "Arial Bold"; */
  color: #1a2332;
  text-align: left;
  font-style: normal;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: 'DMSans Bold';
}

.operations-head /deep/ .el-input__inner {
  height: 3.5714285714285716em;
  box-shadow: 0em 0.14285714285714285em 0.7142857142857143em 0em rgba(30,33,45,0.14);
  border-radius: 0.2857142857142857em;
  border: 0.07142857142857142em solid #8497AB;
}
.text_head{
  font-size:2.14285714em;
  font-weight:700 ;
  font-family: 'DMSans Bold';
}
.operations-content {
  padding: 2.142857142857143em 2.857142857142857em;
  background: #ffffff;
  box-shadow: 0em 0.14285714285714285em 1em 0em rgba(0, 0, 0, 0.12);
  border-radius: 0.2857142857142857em;
  padding-bottom: 0em;
}

.operations-box {
  margin-top: 2.142857142857143em;
  display: flex;
}
/deep/.el-descriptions{
  font-size: 1em;
}
/deep/.el-descriptions__title {
  font-size: 2.142857142857143rem;
  font-family: 'DMSans Bold';
  color: #1a2332;
  text-align: left;
  font-style: normal;
}

/deep/ .el-descriptions-item__label {
  display: block !important;
  margin-right: 2.142857142857143em !important;
  /* padding-bottom: 2.142857142857143em !important; */
}

.operations-label-title {
  width: 13.714285714285714em;
  line-height: 1.8571428571428572em;
  
}
.label_text{
  font-size: 1.42857142857143rem;
  font-family: 'DMSans Bold';
  color: #1a2332;
}
.operations-label-text {
  font-size:1.4285714285714286rem ;
  font-family: 'DMSans Bold';
  color: #29445d;
  line-height: 1.85rem;
  width: 100%;
}

/deep/ .el-textarea__inner {
  padding: 0.71rem 1.14rem;
  font-size:1.4285714285714286rem ;
  color: #29445d;
  border-radius: 0.28rem;
  border: 0.07142857142857142em solid #CCCCCC;
}

.ViewMore {
  line-height: 1.8571428571428572em;
  text-align: left;
  font-style: normal;
  text-decoration-line: underline;
  margin-left: 2.142857142857143em;
}

.operations-box-left {
  margin-right: 7.142857142857143em;
}

.bgyinying {
  height: 0.7142857142857143em;
  width: 100%;
  background-color: #f5f5f5;
}

/deep/ .el-table {
  margin-top: 1.4285714285714286em;
}

/deep/ .el-table th.el-table__cell {
  background-color: #f2f5f9 !important;
}

.jiange {
  color: #cccccc;
  margin: 0 1.4285714285714286em;
  font-size: 1.4285714285714286em;
  /* font-size: 1.25em; */
}

.contclass {
  display: inline-block;
  height: 1.57rem;
  line-height: 1.57rem;
  font-size:1.4285714285714286rem ;
  color: #1290c9;
  font-style: normal;
}

/deep/ .el-table .cell {
  font-size: 1.4285714285714286rem;
  color: #1a2332;
  line-height: 2.14rem;
}


.last-colmun {
  padding: 2em 0em;
  border-bottom: 0.07142857142857142em solid #cccccc;
}

.dingwei {
  position: absolute;
  top: -2.5em;
  right: 0em;
}

.mr10 {
  margin-right: 0.7142857142857143em;
}

.avatar-uploader {
  display: flex;
  justify-content: center;
  align-items: center;
}

/deep/ .el-table .el-input__inner {
  height: 3.5714285714285716rem;
  line-height: 3.5714285714285716rem;
  color: #1A2332;
  border: 0.07142857142857142em solid #8497AB;
}

/deep/ .el-descriptions-item__cell {
  padding-bottom: 2.142857142857143em !important;
}

/deep/ .avatar-uploader .el-upload {
  background: #F5F5F5;
  border: 0.07142857142857142em dashed #cccccc;
  border-radius: 0.2857142857142857em;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

/deep/ .avatar-uploader .el-upload:hover {
  border-color: #1290c9;
}

/deep/ .avatar-uploader-icon {
  font-size:1.4285714285714286rem ;
  color: #8c939d;
  width: 4.85rem;
  height: 4.85rem;
  line-height: 4.85rem;
  text-align: center;
}

/deep/ .avatar {
  width: 5em;
  height: 5em;
  display: block;
}

.input-str {
  width: 28.57rem;
  font-size:1.4285714285714286rem ;
  color: #a2a9b0;
  line-height:1.64rem;
}

/deep/.el-input__inner:focus {
  border: 0.07142857142857142em solid #8497ab;
}



.operations-content /deep/ .el-table .el-table__cell {
  /* padding: 0; */
}

.operations-content /deep/ .el-input .el-input__inner {
  border: 0.07142857142857142em solid #8497AB;
}

/deep/ .el-table thead {
  height: 3.5714285714285716em;
}

.edit_icon, .edit_icon_dis {
  margin-left: -0.35714285714285715em;
  margin-top: 0.35714285714285715em;
}

.edit_icon path  {
  fill: #1290C9;
}

.edit_icon_dis path {
  fill: #8497AB;
}
</style>